<template>
  <layout title="Dashboard">
    <template v-slot:menu>
      <v-spacer />
    </template>
  </layout>
</template>

<script>
import Layout from '../../layouts/Settings.vue';

export default {
  components: { Layout },
};
</script>
